/**
 *  ・定義ファイル名
 *    入力チェック定義ファイル
 *  ・概要
 *    各業務フローにて実施される入力チェックをまとめたファイル
 *    各プロセスのステータス毎に記述する
 */
interface Rules {
  id: string;
  rule: string[];
  referenceField?: {
    id: string;
    rule: string;
    addValue?: boolean;
  }[];
}

// 全てReadOnlyにするアクション
export const readOnlyAction = ['DeleteStatus1'];
export const inputRules: { [k: string]: Rules[] } = {
  // TODO: 必要に応じて入力チェック用のルールを追加
  regist: []
};
