


































































/** デコレータ利用のためComponent, Vueをimport **/
import { Component, Vue } from 'vue-property-decorator';
/** 画面で必要なVuexの各stateをimport **/
import hexabaseState from '@/store/hexabase';
import userState from '@/store/user';
/** ドメイン単位の設定ファイルから必要な設定をimport **/
import {
  documentCategoryDBName,
  documentCategoryDBDeleteActionDisplayId,
  documentCategoryDBStatusDisplayId,
  documentCategoryDBFailureStatusMessage,
  documentCategoryDBNotSearchStatusId,
  documentCategoryListDisplayId,
  documentCategoryListPath
} from '@/domains/documentManagement/constants/constants';
/** 画面単位の設定ファイルから必要な設定をimport **/
import localDisplayConfig from '@/domains/documentManagement/constants/documentCategoryEdit';
/** バックエンド設定で賄えないフロント定義によるフィールド入力ルールをimport **/
import { inputRules, readOnlyAction } from '@/domains/documentManagement/constants/inputCheck';
/** 画面で利用するバックエンド設定のimport **/
import {
  ActionButtons,
  FieldChildrenProp,
  FieldsInfo,
  StatusActions,
  ItemActions,
  StatusList,
  ActionFieldSetting,
  MasterData,
  RegistData,
  GetInputFieldsActions
} from '@/services/hexabase/types';
/** 画面で利用するモデルファイルをimport **/
import DocumentCategoryModel from '@/domains/documentManagement/models/documentCategoryModel';
/** 画面で利用するコンポーネントをimport 下部に存在する＠Componentにも記載する **/
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import ActionButtonArea from '@/components/common/modules/ActionButtonPanel.vue';
import CreateBasicInfo from '@/domains/documentManagement/components/CreateBasicInfo.vue';
import ExecAction from '@/components/common/modules/ExecAction.vue';
import TransitionDialog from '@/components/common/modules/TransitionDialog.vue';
import IconTextLink from '@/components/common/elements/IconTextLink.vue';
/** 画面で利用する共通ライブラリをimport **/
// import { DateTime } from 'luxon';

interface Buttons {
  id: number;
  type: string;
  name: string;
  value: string;
  route?: string;
}
interface Rules {
  id: string;
  rule: string[];
  referenceField?: {
    id: string;
    rule: string;
  }[];
}
@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    ActionButtonArea,
    CreateBasicInfo,
    TransitionDialog,
    ExecAction,
    IconTextLink
  }
})
export default class DocumentCategoryEdit extends Vue {
  // 各種設定値をリテラル宣言（ ※コピー後、必要に応じてリテラル値を変更 ）
  readonly baseTranDatabaseName = documentCategoryDBName; // 対象のデータベース名(ja)
  readonly documentCategoryListDisplayId = documentCategoryListDisplayId; // 削除処理後に戻る検索画面ID
  readonly deleteActionId = documentCategoryDBDeleteActionDisplayId; // 削除アクションID
  // ===============================================================

  readonly projectId = hexabaseState.applicationId;
  readonly employeeId = userState.getUserEmployeeId;
  readonly basicModel = new DocumentCategoryModel();

  // 各入力フィールド情報格納プロパティ
  basicFieldsData: Array<FieldChildrenProp> = [];
  basicFieldCols = '';

  // アクション情報格納プロパティ
  statusButton: Array<StatusActions> = [];
  statusAction: Array<StatusActions> = [];
  itemActions: Array<ItemActions> = [];
  execAllowActions: Array<ActionButtons> = [];
  selectAction = '';

  // ステータス情報格納プロパティ
  statusList: Array<StatusList> = [];
  statusRules: { [k: string]: Rules[] } = {};

  // マスタデータ関連格納プロパティ
  masterData: MasterData = {};

  // 登録用情報格納プロパティ
  registData: RegistData = {
    item: {},
    return_item_result: true, // true指定すると、登録されたアイテム情報を返します
    related_ds_items: {} // 関連するデータストアの新規・更新・削除を指定  詳細は以下を参照
  };

  // 編集対象アイテム情報
  itemId = this.$route.params.id;
  revNumber = 0;
  currentData: Array<FieldsInfo> = [];

  // ToDo: 汎用的に不必要であれば削除する
  // // 備考ボタン内テキスト
  // defaultNoteText = '備考';
  // existenceNoteText = '備考有';

  // dbID格納プロパティ
  mainDsId = '';

  // アイテム登録後ダイアログ
  dialogStatus = false;
  // ダイアログ内ローディングフラグ
  isLoading = false;
  // ダイアログ内テキスト
  editText = '';
  // ダイアログ内ボタン
  buttons: Array<Buttons> = this.basicModel.deepCopy(localDisplayConfig.editFinishAction);

  // 対象アイテムの現ステータス（日本語名）
  displayStatus = '';
  // 対象アイテムの現ステータスID
  statusId = '';

  async created() {
    try {
      // ローディングをセット
      hexabaseState.setIsLoading(true);

      this.mainDsId = hexabaseState.datastoreIds[this.baseTranDatabaseName];
      // 既存アイテムを取得しアイテム情報・アクション情報を保持
      const basicResult = await this.basicModel.getCurrentItem(
        hexabaseState.applicationId,
        this.mainDsId,
        this.itemId,
        documentCategoryDBNotSearchStatusId
      );
      // アイテム詳細APIの結果から紐づくステータスアクションを取得
      if (this.basicModel.isNullOrUndefined(basicResult.status_actions))
        basicResult.status_actions.forEach(v => this.statusAction.push(v));
      // アイテム詳細APIの結果から紐づくアイテムアクションを取得
      if (this.basicModel.isNullOrUndefined(basicResult.item_actions))
        basicResult.item_actions.forEach(v => this.itemActions.push(v));
      // アイテム詳細APIの結果から紐づくステータスリストを取得
      if (this.basicModel.isNullOrUndefined(basicResult.status_list))
        basicResult.status_list.forEach(v => this.statusList.push(v));
      // ステータスが取得できているならステータス用の変数へ値をセットする
      if (this.statusList.length > 0) {
        const statusValue = basicResult.field_values.find(v => v.field_id === documentCategoryDBStatusDisplayId)
          ?.value;
        if (statusValue === undefined) return;
        const currentStatus = this.statusList.find(v => v.status_id === statusValue);
        this.statusId = currentStatus?.display_id !== undefined ? currentStatus.display_id : '';
        this.displayStatus =
          currentStatus !== undefined ? currentStatus.status_name : documentCategoryDBFailureStatusMessage;
        this.statusRules = inputRules;
      }
      // ステータス移管に使用するためリビジョン番号を格納
      this.revNumber = basicResult.rev_no;
      // 現在のアイテムが持つ登録値を設定（初期表示に使用）
      this.currentData = basicResult.field_values;

      // ステータスアクションを実行可能なアクションとして設定
      // 詳細画面上部にボタンとして表示する
      this.execAllowActions = this.statusAction;

      // 基本情報を要素にセットする
      const basicField = await this.basicModel.getBasicFieldsEdit(
        this.projectId,
        this.mainDsId,
        this.itemId,
        this.itemActions,
        this.currentData
      );
      if (basicField !== undefined) {
        this.basicFieldCols = basicField.basicFieldCols;
        this.basicFieldsData = basicField.basicFieldsData;
      }
      // 画面上部に表示するボタンは実行可能なアクションをすべて表示
      // ユーザ毎の制御や特殊なパターンは別途関数を用意してフィルターする
      this.statusButton = this.execAllowActions;
    } catch (e) {
      this.setError(e as string | object);
    } finally {
      hexabaseState.setIsLoading(false);
    }
  }

  /**
   * 初回アクセス時の入力制限
   */
  addInputProhibited(data: FieldChildrenProp[], options?: Array<string>): FieldChildrenProp[] {
    return data.map(field => {
      if (field.props === undefined) return field;
      if (options !== undefined && options.length > 0) {
        // 指定された項目の入力制限は行わない
        if (options.filter(option => field.display_id === option).length > 0) {
          return field;
        }
      }
      if (field.dataType === 'dslookup') {
        field.props!.isBtnVisible = false;
      }
      if (field.component === 'MasterSelectControl') {
        field.props!.isLeftSideInputReadOnly = true;
      }
      field.props!.isReadonly = true;
      field.props!.isDisabled = true;
      return field;
    });
  }

  /**
   * 子コンポーネントからEmitされる値インサート用に加工（基本情報）
   * （値が渡されるか確認用）
   * @module catchInputBasic
   * @param response - 子コンポーネントより渡される値
   */
  catchInputBasic(response: any) {
    const index = this.basicFieldsData.findIndex(v => v.display_id === response.name);
    switch (this.basicFieldsData[index].component) {
      case 'MasterSelectControl':
      case 'MasterSelectSetControl':
      case 'MasterSelectMultiControl':
      case 'MasterSelectMultiSetControl':
        this.$set(this.basicFieldsData[index].props!, 'value', { item_id: response.value });
        break;
      default:
        this.$set(this.basicFieldsData[index].props!, 'value', response.value);
    }

    // 入力に紐付け入力チェックがある場合
    const action = this.selectAction;
    if (this.statusRules !== undefined && this.statusRules[action] !== undefined) {
      const validateRules = this.basicModel.addLinkingRuleByInput(
        this.statusRules[action],
        response,
        this.basicFieldsData
      );
      if (validateRules.length > 0) {
        for (const ruleIndex in validateRules) {
          this.$set(
            this.basicFieldsData[validateRules[ruleIndex].key].props!,
            'rules',
            validateRules[ruleIndex].rules
          );
        }
      }
    }
    // 入力値から登録データを生成
    const name = response.name;
    const value = response.value;
    this.registData.item[name] = value;
  }

  /**
   * ルーター設定ファイルよりパンくずリスト取得
   * @module getBreadCrumbs
   * @return {array} - breadcrumbs 情報
   */
  getBreadCrumbs(): [] {
    if (
      this &&
      this.$route &&
      this.$route.meta &&
      this.$route.meta.breadcrumbs &&
      this.$route.matched.some(obj => obj.meta.breadcrumbs)
    ) {
      return this.$route.meta.breadcrumbs;
    }
    return [];
  }

  /**
   * アクションパネルのボタンを押下した際の次処理を進める
   * @param type - イベント情報として押下したボタン種類が返ってくる
   */
  async buttonAction(type: string) {
    try {
      // 全要素ReadOnlyアクションか判定
      const isReadOnly = readOnlyAction.includes(String(type));

      // ローディングをセット
      hexabaseState.setIsLoading(true);

      // アクション毎の見出し要素入力制限反映
      const copy = this.basicModel.modifyButtonProp(this.execAllowActions, type);
      if (copy !== undefined && copy.length > 0) {
        this.execAllowActions.splice(0, copy.length, ...copy);
      }
      // 見出し側のアクション設定
      const execAction = this.execAllowActions.find(action => action.display_id === type);
      // 選択したアクションのアクションID
      this.selectAction = execAction!.display_id;
      // 見出しの指定されたアクションに紐づくフィールド情報を取得する
      const inputFields = await this.basicModel.getFieldsByActionId(
        this.mainDsId,
        execAction!.action_id
      );
      // 取得したアクションに紐づく情報から基本情報部のフィールドを整形する
      const result = this.modifyFields(this.basicFieldsData, inputFields, type);
      this.basicFieldsData = isReadOnly ? this.addInputProhibited(result) : result;

      // 入力に紐付け入力チェックがある場合
      if (this.statusRules !== undefined && this.statusRules[this.selectAction] !== undefined) {
        const validateRules = this.basicModel.addLinkingRuleByInitial(
          this.statusRules[this.selectAction],
          this.registData.item,
          this.basicFieldsData
        );
        if (validateRules.length > 0) {
          for (const ruleIndex in validateRules) {
            this.$set(
              this.basicFieldsData[validateRules[ruleIndex].key].props!,
              'rules',
              validateRules[ruleIndex].rules
            );
          }
        }
      }
    } catch (e) {
      this.setError(e as string | object);
    } finally {
      hexabaseState.setIsLoading(false);
    }
  }

  /**
   * フィールド整形用関数をまとめた関数
   * @param data - テーブルが持つフィールドデータ
   * @param inputFields - テーブルアクションに対応するフィールドデータ
   * @param type - 押下したボタンのアクションID
   * @returns modifyInputAllow(data, inputFields.action_field_settings)
   */
  modifyFields(
    data: Array<FieldChildrenProp> | Array<FieldChildrenProp[]>,
    inputFields: GetInputFieldsActions,
    type: string
  ) {
    data = this.addInputRules(
      data as Array<FieldChildrenProp>,
      inputFields.action_field_settings,
      type
    );
    return this.modifyInputAllow(data, inputFields.action_field_settings) as Array<
      FieldChildrenProp
    >;
  }
  /**
   * テーブルが持つフィールドデータに対して
   * テーブルアクションに対応するフィールド設定から
   * 入力ルールを付与する
   * @param fields - テーブルが持つフィールドデータ
   * @param fieldSetting - テーブルアクションに対応するフィールド設定
   * @param type - 押下したボタンのアクションID
   * @returns ルールが付与されたフィールド
   */
  addInputRules(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting },
    type: string
  ) {
    return fields.map(field => {
      if (field.props === undefined) return field;

      const ruleArray = [];

      // Hexa側設定必須項目
      if (fieldSetting[field.field_id] !== undefined) {
        // 必須項目確認
        if (fieldSetting[field.field_id].mandatory) {
          const requiredName = field.component === 'SelectArea' ? 'selectRequired' : 'required';
          ruleArray.push(requiredName);
        }
      }

      // アプリ側設定項目
      if (this.statusRules !== undefined && this.statusRules[type] !== undefined) {
        const row = this.statusRules[type].find(val => val.id === field.display_id);
        if (row !== undefined) {
          ruleArray.push(row.rule);
        }
      }

      if (field.props!['rules'] === undefined) {
        field.props!['rules'] = '';
      }
      if (ruleArray.length > 0) field.props!['rules'] = ruleArray.join('|');
      return field;
    });
  }
  /**
   * fieldsに対してfieldSettingの表示・入力・必須の設定情報を反映する
   * @param fields - テーブルが持つフィールドデータ
   * @param fieldSetting - テーブルアクションに対応するフィールド設定
   * @returns 表示・入力・必須の設定情報が反映されたフィールド
   */
  modifyInputAllow(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting }
  ) {
    return fields.map(field => {
      if (fieldSetting[field.field_id] !== undefined) {
        const row = fieldSetting[field.field_id];
        if (row.update) {
          field.props! = { ...field.props!, ...{ isDisabled: false, isReadonly: false } };
        }
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = true;
          field.props!.isLeftSideInputReadOnly = false;
        }
        if (field.dataType === 'autonum') {
          field.props!.isDisabled = true;
          field.props!.isReadonly = false;
        }
        field = {
          ...field,
          ...{
            show: row.show,
            update: row.update,
            mandatory: row.mandatory
          }
        };
      }
      return field;
    });
  }

  /**
   * 画面下部ボタンを押下した際に実行される処理
   * @param type - 実行、キャンセルボタンを判別する文字列を受け取る
   */
  async execAction(type: string) {
    switch (type) {
      case 'cancel': {
        this.$router.go(0);
        break;
      }
      case 'exec': {
        try {
          // ローディングをセット
          hexabaseState.setIsLoading(true);
          this.editText = '';

          const response = await this.basicModel.execStatusAction(
            this.registData,
            hexabaseState.applicationId,
            this.mainDsId,
            this.itemId,
            this.execAllowActions,
            this.revNumber,
            this.selectAction
          );

          if (response !== undefined && response.text !== undefined && response.text.length > 0) {
            this.editText = response.text;
          }

          if (this.selectAction == this.deleteActionId) {
            this.buttons = this.basicModel.deepCopy(localDisplayConfig.deleteFinishAction);
          }

          if (response !== undefined && response.button !== undefined) {
            this.buttons.splice(0);
            this.buttons.push(response.button);
          }
        } catch (e) {
          this.setError(e as string | object);
        } finally {
          hexabaseState.setIsLoading(false);
          this.dialogStatus = true;
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * アイテムを登録後ダイアログ表示等を初期化します
   */
  editFinish() {
    this.dialogStatus = false;
    this.isLoading = false;
    this.selectAction = '';
  }

  /**
   * 検索画面へ遷移する
   */
  moveList() {
    this.$router.push({ path: `/${documentCategoryListPath}`, query: { searchConditions: 'true' }});
  }

  /**
   * API実行結果から受け取ったエラーメッセージを共通のエラーダイアログに表示する
   * @param errorMessage - API実行結果から受け取ったエラーメッセージ
   * @returns {void}
   */
  setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.basicModel.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }
}
