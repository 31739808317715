












































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Icon from '@/components/common/elements/Icon.vue';
import Label from '@/components/common/elements/Label.vue';
import Button from '@/components/common/elements/Button.vue';
import MultipleLineText from '@/components/common/elements/MultipleLineText.vue';
import InputArea from '@/components/common/modules/InputArea.vue';
import DatePickerArea from '@/components/common/modules/DatePickerArea.vue';
import ComboBoxArea from '@/components/common/modules/ComboBoxArea.vue';
import SelectArea from '@/components/common/modules/SelectArea.vue';
import IconTextLink from '@/components/common/elements/IconTextLink.vue';

import AutoCreateForm from '@/components/form/AutoCreateForm.vue';
import { FieldChildrenProp } from '@/services/hexabase/types';
import { FileFormResponse } from '@/constants/form/types';

@Component({
  components: {
    SingleText,
    Icon,
    Label,
    Button,
    MultipleLineText,
    InputArea,
    DatePickerArea,
    ComboBoxArea,
    SelectArea,
    AutoCreateForm,
    IconTextLink
  }
})
export default class CreateBasicInfo extends Vue {
  @Prop()
  basicFieldData!: Array<FieldChildrenProp>;
  @Prop()
  basicFieldCols!: string;
  @Prop({ default: false })
  isScrren?: boolean;
  // 表示ステータス
  @Prop({ default: '' })
  displayStatus!: string;
  // 基本情報パネル開閉アイコン
  arrowIcon = 'mdi-menu-up';
  isBasicAreaStatus = true;

  /**
   * 基本情報パネルの開閉時の処理
   * 主にCSSの値変更
   * @module toggleBasicArea
   * @return {void} - メンバ変数の変更
   */
  toggleBasicArea(): void {
    this.isBasicAreaStatus = !this.isBasicAreaStatus;
    if (this.isBasicAreaStatus) {
      this.arrowIcon = 'mdi-menu-up';
    } else {
      this.arrowIcon = 'mdi-menu-down';
    }
  }

  /**
   * 子コンポーネントからEmitされる値を親に渡す
   * （値が渡されるか確認用）
   * @module catchInput
   * @param {any} response - 子コンポーネントより渡される値
   * @return response - Emit
   */
  @Emit('catchInput')
  catchInput(response: any) {
    return response;
  }

  @Emit('emitFileData')
  private emitFileData(data: FileFormResponse): FileFormResponse {
    return data;
  }
}
