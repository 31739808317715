






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import Label from '@/components/common/elements/Label.vue';
import Input from '@/components/common/elements/Input.vue';
import Select from '@/components/common/elements/Select.vue';

@Component({
  components: { Label, Input, Select }
})
export default class ComboBoxArea extends Vue {
  // 仮Props 仕様が決まり次第 正しく制作
  @Prop()
  private value?: string;
  @Prop()
  private labelText?: string;

  // セレクトリストコンポーネントに渡す設定値
  private selectDemo = {
    label: '',
    name: 'select',
    disabled: false,
    options: [
      { value: 1, text: '購買商品1' },
      { value: 2, text: '購買商品2' },
      { value: 3, text: '購買商品3' }
    ]
  };
}
